import React from "react";

import "./CheckoutPaymentMethod.css";
import IconRadio from "./assets/icon_radio.svg";
import IconNPay from "./assets/icon_logo_npay.svg";
import IconKakaoPay from "./assets/icon_logo_kakaopay.png";
import "./CheckoutPaymentMethod.css";

/**
 * 결제 수단 컴포넌트
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckoutPaymentMethod() {
  return (
    <div className="CheckoutPaymentMethod">
      <div>결제 수단</div>
      <div className="CheckoutPaymentMethod-content">
        <div className="CheckoutPaymentMethod-select">
          <img alt={"radio"} src={IconRadio} />
          <div>신용/체크카드, 간편결제</div>
        </div>
        <div className="CheckoutPaymentMethod-pay">
          <img alt={"naver-pay"} src={IconNPay} />
          <img alt={"kakao-pay"} src={IconKakaoPay} />
        </div>
      </div>
    </div>
  );
}
