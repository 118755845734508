const REGEX_EMAIL = /\S+@\S+\.\S+/;
const REGEX_NICKNAME = /^(?=.*[a-z])[a-z0-9]{6,20}$/;
export const REGEX_PASSWORD = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
const REGEX_ONLY_NUMBER = /^[0-9]+$/;


export const EMAIL_VALIDATION = {
    value: REGEX_EMAIL,
    message: "이메일이 올바르지 않습니다."
}

export const NICKNAME_VALIDATION = {
    value: REGEX_NICKNAME,
    message: "6~20자 이내의 영문 소문자를 입력해 주세요(숫자 포함 가능)."
}

export const PASSWORD_VALIDATION = {
    value: REGEX_PASSWORD,
    message: "8~20자 이내의 영문과 숫자 조합으로 입력해주세요."
}

export const NUMBER_VALIDATION = {
    value: REGEX_ONLY_NUMBER,
    message: "하이픈(-)을 제외한 숫자만 입력해 주세요."
}


