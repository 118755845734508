import React from 'react';
import {gql} from 'apollo-boost';
import {Helmet} from 'react-helmet';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';

import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import Rating from '../../component/rating/Rating';
import UserPreview from '../../component/userPreview/UserPreview';
import {productUrl, sellerUrl} from '../../util/url';
import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from '../product/Product';
import checkCircle from './assets/checkCircle.svg';
import minusCircle from './assets/minusCircle.svg';
import'./Order.css';
import {currencyFormat} from '../../util/locale';
import {formatDate} from '../../util/date';
import iconInfo from '../checkout/assets/icon_info.svg';

const DAUM_DELIVERY_STATUS = "https://search.daum.net/search?&q=";

const GET_PURCHASED_ORDER = gql`
    query getPurchasedOrder($orderID: Int!) {
        seePurchasedOrder(order_id: $orderID) {
            id
            price
            shipping_fee
            purchase_id
            status
            address
            postal_code
            invoice_number
            createdAt
            updatedAt
            completedAt
            deliveredAt
            settlementEstimatedAt
            courier_service
            customer_name
            customer_phone
            message
            is_island
            seller {
                id
                rating
                review_count
                sold_count
                user {
                    id
                    username
                    nickname
                    follower_count
                    resizedSmallImage
                }
            }
            product {
                ...ProductDetailsPreloadFragment
            }
            receipt {
               productPrice
               shippingFee
               cardFee
               paidAmount
               feeAssigned
            }
        }
    }
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * 구매내역 상세 페이지
 * @returns {JSX.Element}
 */
export default function Order() {
    const {orderId} = useParams();
    const location = useLocation();

    const { data, loading, error } = useQuery(
        GET_PURCHASED_ORDER,
        {
            variables: {
                orderID: parseInt(orderId),
            },
        }
    );

    if (loading && (!data || !data.seePurchasedOrder)) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    // build the UI elements
    const order = data.seePurchasedOrder;

    const orderDate = (status) => {
        if (status === 'cancelled') {
            return `취소완료 • ${formatDate(order.updatedAt)}`;
        } else {
            return `결제완료 • ${formatDate(order.createdAt)}`;
        }
    }

    const shippingDate = (status) => {
        if(status === 'pending') {
            return (
                <div className="Order-ShippingInfo-status d-flex align-items-center">
                    <img className="Order-Check-image" src={minusCircle} alt=""/>
                    <span className="Order-date red">배송 대기중</span>
                </div>
            )
        } else {
            return (
                <div className="Order-ShippingInfo-status d-flex align-items-center">
                    <img className="Order-Check-image" src={checkCircle} alt=""/>
                    <span className="Order-date">판매자 발송완료 • {formatDate(order.deliveredAt)}</span>
                </div>
            )
        }
    }

    const shippingCompany = () => {
        return (
            <>
                <div className='d-flex Order-ShippingInfo-company'>
                    <div className="d-flex flex-column">
                        <span className="Order-ShippingInfo-address">업체</span>
                        <span className="Order-ShippingInfo-address-detail">{order.courier_service}</span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="Order-ShippingInfo-address">운송장 번호</span>
                        <span className="Order-ShippingInfo-address-detail">{order.invoice_number}</span>
                    </div>
                </div>
                <div className="d-flex Order-ShippingInfo-button-container">
                    <button className="Order-ShippingInfo-button" onClick={trackDelivery}>배송조회 →</button>
                    <button className="Order-ShippingInfo-button">
                        <Link to='/download'>구매후기 수정 →</Link>
                    </button>
                </div>
            </>
        )
    }

    const trackDelivery = () => {
        const courierService = order.courier_service === 'GS Postbox 택배' ? 'GS 편의점택배' : order.courier_service;
        const query = !courierService || courierService === "기타" || courierService === "농협택배" ?
            "택배배송 조회" :
            `${courierService.replace(/\s+\(.+\)$/, '')} ${order.invoice_number}`;
        window.open(
            `${DAUM_DELIVERY_STATUS}${encodeURIComponent(query)}`
        );
    }

    return (
        <section className="Order container">
            <Helmet>
                <title>구매 내역 상세 | MY | 후루츠패밀리</title>
                <meta name="description" content={'후루츠패밀리에서 구매한 상세 내역입니다.'} />
            </Helmet>

            <h1 className="Order-title">구매 내역 상세</h1>
            <div className="Order-ProductInfo d-flex">
                <Link
                    to={productUrl(order.product)}
                    state={{background: location}}
                >
                    <img className="Order-Product-image"
                         loading="lazy"
                         alt=""
                         src={order.product.resizedSmallImages[0]} />
                </Link>
                <div className="Order-Product-detail d-flex flex-column">
                    <span className="Order-Product-price">{currencyFormat(order.product.price, 'KRW')}</span>
                    <span className="Order-Product-title">{order.product.title}</span>
                    <span className="Order-Product-brand">{order.product.brand}</span>
                </div>
            </div>
            <div className="Order-PurchaseInfo">
                <h2>결제정보</h2>
                <div className="Order-PurchaseInfo-status d-flex align-items-center">
                    <img className="Order-Check-image" src={checkCircle} alt=""/>
                    <span className="Order-date">{orderDate(order.status)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span className="Order-PurchaseInfo-price-type">판매가격</span>
                    <span className="Order-PurchaseInfo-price">{currencyFormat(order.receipt ? order.receipt.productPrice : (order.price - order.shipping_fee), 'KRW')}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="Order-PurchaseInfo-price-type">배송비</span>
                    <span className="Order-PurchaseInfo-price">{currencyFormat(order.receipt ? order.receipt.shippingFee : order.shipping_fee, 'KRW')}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="Order-PurchaseInfo-fee">
                        <span className="Order-PurchaseInfo-price-type">
                            수수료
                        </span>
                        <a href="https://intercom.help/fruits-family-co/articles/3598730"
                           rel="noopener noreferrer"
                           target="_blank">
                            <img alt={'info'} src={iconInfo}/>
                        </a>
                    </div>
                    <span
                        className="Order-PurchaseInfo-price">{(order.receipt && order.receipt.feeAssigned === 'SELLER') ?
                        '0원' : currencyFormat((order.receipt && order.receipt.cardFee) || 0, 'KRW')}</span>
                </div>
                <div className="Order-PurchaseInfo-total d-flex justify-content-between">
                    <span className="Order-PurchaseInfo-total-price-type">총 결제금액</span>
                    <span className="Order-PurchaseInfo-total-price">{currencyFormat(order.receipt ? order.receipt.paidAmount : order.price, 'KRW')}</span>
                </div>
            </div>
            {order.status !== 'cancelled' &&
                <div className="Order-ShippingInfo">
                    <h2>배송지 정보</h2>
                    {shippingDate(order.status)}
                    <div className="d-flex flex-column Order-ShippingInfo-address-info">
                        <span className="Order-ShippingInfo-address">배송지</span>
                        <span className="Order-ShippingInfo-address-detail">{order.customer_name} ({order.customer_phone ? order.customer_phone : "핸드폰 번호 미등록"})</span>
                        <span className="Order-ShippingInfo-address-detail">({order.postal_code}) {order.address}</span>
                        <span className="Order-ShippingInfo-address-detail">[{order.message ? order.message : "요청사항 없음"}]</span>
                    </div>
                    {order.status !== 'pending' && shippingCompany()}
                </div>
            }
            <div className="Order-SellerInfo">
                <h2>판매자 정보</h2>
                <div className="d-flex flex-column align-items-center">
                    <Link to={sellerUrl(order.seller)}>
                        <div className="Order-SellerInfo-image">
                            <UserPreview user={{...order.seller.user, resizedSmallImage: order.seller.user.resizedSmallImage}} />
                        </div>
                    </Link>
                    <div className="Profile-rating">
                        <Rating rating={order.seller.rating} />
                    </div>
                    <span className="Order-SellerInfo-nickname">{order.seller.user.nickname}</span>
                    <span className="Order-SellerInfo-username">@{order.seller.user.username}</span>
                </div>
                <div className="d-flex Order-SellerInfo-status justify-content-center align-items-center">
                    <div className="d-flex flex-column align-items-center Order-SellerInfo-status-item">
                        <span className="Order-SellerInfo-status-value">{order.seller.sold_count}</span>
                        <span className="Order-SellerInfo-status-type">SOLD</span>
                    </div>
                    <div className="d-flex flex-column align-items-center Order-SellerInfo-status-item">
                        <span className="Order-SellerInfo-status-value">{order.seller.review_count}</span>
                        <span className="Order-SellerInfo-status-type">REVIEW</span>
                    </div>
                    <div className="d-flex flex-column align-items-center Order-SellerInfo-status-item">
                        <span className="Order-SellerInfo-status-value">{order.seller.user.follower_count}</span>
                        <span className="Order-SellerInfo-status-type">FOLLOWERS</span>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <button className="Order-help-button">
                    <a href="https://intercom.help/fruits-family-co"
                       rel="noopener noreferrer"
                       target="_blank">
                        후루츠팀이 도와드릴까요?
                    </a>
                </button>
            </div>

        </section>
    );
}
