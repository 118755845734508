import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../../component/loading/Loading";

export function CheckoutFail() {
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get("message");
  const navigate = useNavigate();

  useEffect(() => {
    alert(errorMessage);
    navigate(-1);
  }, []);

  return <Loading />;
}
