import React, {useEffect, useState} from 'react';
import './TestEnvironmentBanner.css';
import IconWarning from './assets/warning.svg';
import IconRightArrow from './assets/chevron_smaller_right.svg';
import PropTypes from "prop-types";
import {isProductionUrl} from "../../util/util";

const BASE_URL = 'https://fruitsfamily.com';

/**
 * 테스트 환경 안내 팝업
 * @param {path} 이동할 path  
 * @returns 
 */
const TestEnvironmentBanner = ({ path }) => {
  const [isProduction, setIsProduction] = useState(true);

  useEffect(() => {
    const hostname = window.location.hostname;
    setIsProduction(isProductionUrl(hostname));
  }, []);  

  const handleClick = () => {
      window.location.href = path ? `${BASE_URL}/${path}` : BASE_URL;
  };

  if (isProduction) return null;

  return (
    <div className="TestEnvironmentBanner mt-3" onClick={handleClick}>
      <div className="TestEnvironmentBanner-content">
        <span className="TestEnvironmentBanner-warning">
            <img src={IconWarning} alt="waring" />
        </span>
        <span className='TestEnvironmentBanner-message'>
          <span>현재 테스트용 환경에 접속 중입니다.</span>
          <span>후루츠 공식 홈페이지에서 회원가입을 진행해주세요.</span>
        </span>
        <span className="TestEnvironmentBanner-arrow">
            <img src={IconRightArrow} alt="arrow" />
        </span>
      </div>
    </div>
  );
};

export default TestEnvironmentBanner;

TestEnvironmentBanner.propTypes = {
    path: PropTypes.string
}   