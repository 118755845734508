import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {currencyFormat} from '../../util/locale';
import SafetyPurchaseInfo from "../../component/safetyPurchaseInfo/SafetyPurchaseInfo";

import './CheckoutInvoice.css';
import {logBeginCheckout} from "../../util/analytics";
import toast from "react-hot-toast";
import {homeUrl, loginUrl} from "../../util/url";
import {useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/useAuthStore";

import iconInfo from './assets/icon_info.svg';

const CURRENCY = 'KRW';

/**
 * 주문 Invoice
 * @param product the checked out product
 * @param receipt receipt
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckoutInvoice({ product, receipt }) {
    const navigate = useNavigate();
    const {isLogin, user} = useAuthStore();

    useEffect(() => {
        if (product) {
            logBeginCheckout('webapp', product);

            //판매자 구매자 동일 경우 체크
            if (user) {
                if (product.seller.id === user.id) {
                    toast('판매자와 구매자는 동일할 수 없습니다.')
                    navigate(homeUrl());
                }
            }
        }
        // 미로그인 시 로그인 페이지로 이동
        if (!isLogin) {
            toast('간편 로그인 후 구매 가능합니다.');
            navigate(loginUrl());
        }

    }, [user]);

    return (
        <div className='CheckoutInvoice'>
            <div className='CheckoutInvoice-safety-purchase-info'>
                <SafetyPurchaseInfo />
            </div>
            <div className='CheckoutInvoice-price'>
                <div>상품 금액</div>
                <div>
                    {currencyFormat(receipt.productPrice, CURRENCY)}
                </div>
            </div>
            <div className='CheckoutInvoice-price'>
                <div>배송비</div>
                <div>
                    {currencyFormat(receipt.shippingFee, CURRENCY)}
                </div>
            </div>
            <div className='CheckoutInvoice-price'>
                <div className='CheckoutInvoice-fee'>수수료
                    <a href="https://intercom.help/fruits-family-co/articles/3598730"
                       rel="noopener noreferrer"
                       target="_blank">
                        <img alt={'info'} src={iconInfo}/>
                    </a>
                </div>
                <div>
                    {currencyFormat(receipt.cardFee, CURRENCY)}
                </div>
            </div>
            <div className='CheckoutInvoice-price total'>
                <div>총 결제 금액</div>
                <div>
                    {currencyFormat(receipt.paidAmount, CURRENCY)}
                </div>
            </div>
        </div>
    );
}
CheckoutInvoice.propTypes = {
    product: PropTypes.shape({
        seller: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    receipt: PropTypes.object.isRequired,
};
