import React from 'react';
import {useParams} from "react-router-dom";
import {PRODUCT_FRAGMENT} from "../../component/productPreview/ProductPreview";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {Helmet} from "react-helmet";

import {checkoutUrl, webappURL} from "../../util/url";
import CheckoutShipping from "./CheckoutShipping";
import CheckoutInvoice from "./CheckoutInvoice";
import CheckoutProductDetails from "./CheckoutProductDetails";
import CheckoutPaymentMethod from "./CheckoutPaymentMethod";
import Loading from "../../component/loading/Loading";
import Error from "../../component/error/Error";
import MobileMaxWidthWrapper from "../../component/mobileMaxWidthWrapper/MobileMaxWidthWrapper";

import './Checkout.css';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import CheckoutButton from './CheckoutButton';
import FormCheckbox from '../../component/rhf/formCheckBox/FormCheckBox';

// GraphQL queries
export const SEE_PRODUCT = gql`
    query SeeProduct($productId: Int!) {
        seeProduct(id: $productId) {
            ...ProductFragment
            id
            title
            category
            brand
            gender
            resizedSmallImages
            status
            price
            shipping_fee
            shipping_fee_island
            status
            size
            condition
            createdAt
            seller {
                id
                user {
                    id
                    username
                }
            }
        }
    }
    ${PRODUCT_FRAGMENT}
`;


const PREVIEW_ORDER = gql`
  query previewOrder(
    $productId: Int!
  ) {
    previewOrder(
      productId: $productId
    ) {
      receipt {
        productPrice
        shippingFee
        cardFee
        paidAmount
      }
      product {
        id
        title
        description
        brand
        resizedSmallImages
        price
        status
        gender
        fee_assigned
        seller {
          id
          user {
            username
          }
        }
      }
      delivery_address {
        id
        recipient_name
        recipient_phone
        address1
        address2
        postal_code
      }
    }
  }
`

/**
 * Checkout page.
 * @param hostname
 * @returns {JSX.Element}
 * @constructor
 */
export default function Checkout({hostname}) {
    const { register, watch, formState: { isValid } } = useForm();

    const agree1 = watch('agree1');
    const agree2 = watch('agree2');

    // fetch product details
    const {productId: encodedProductId} = useParams();
    const productId = parseInt(encodedProductId, 36);


    const {loading, error, data} = useQuery(
        PREVIEW_ORDER,
        {
            fetchPolicy: 'network-only',
            returnPartialData: false,
            variables: {
                productId
            }
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    return <MobileMaxWidthWrapper className={'max-width-800'}>
        <Helmet>
            <title>주문/결제 - 후루츠패밀리</title>
            <link rel="canonical" href={webappURL(hostname) + checkoutUrl(data.previewOrder.product)} />
        </Helmet>

        <div className='Checkout-container'>
            <h1 className='page-title'>주문 / 결제</h1>
            <h4 className="Checkout-title">
                상품 정보
            </h4>

            <CheckoutProductDetails product={data.previewOrder.product} />

            {data.previewOrder.product.status === 'sold' ? (
                <div className="Checkout-soldout">
                    죄송합니다.<br />
                    아쉽지만 품절이 되었습니다 😢
                </div>
            ) : (
                <div className="Checkout-order">

                    <CheckoutInvoice product={data.previewOrder.product}
                                     receipt={data.previewOrder.receipt}
                                     />
                    <div className="Checkout-divider" />
                    <CheckoutShipping productId={encodedProductId} deliveryAddress={data.previewOrder.delivery_address} />
                    <div className="Checkout-divider" />
                    <CheckoutPaymentMethod />
                    <div className="Checkout-divider" />

                    <div className="Checkout-agree-container">
                        <div className="Checkout-agree-list">
                            <div className={`Checkout-agree-text ${agree1 ? 'Checkout-select-text' : 'Checkout-unselect-text'}`}>
                                주문할 상품의 결제,배송,주문정보를<br/>확인하였으며 이에 동의합니다.
                            </div>
                            <FormCheckbox register={register} name={'agree1'} required={true} />
                        </div>
                        <div className="Checkout-agree-list">
                            <div className={`Checkout-agree-text ${agree2 ? 'Checkout-select-text' : 'Checkout-unselect-text'}`}>
                                배송 완료 72시간 후 자동으로 구매확정 됩니다.
                            </div>
                            <FormCheckbox register={register} name={'agree2'} required={true} />
                        </div>
                        <CheckoutButton isValid={isValid && data.previewOrder.delivery_address !== null}
                                        product={data.previewOrder.product}
                                        deliveryAddress={data.previewOrder.delivery_address}
                                        receipt={data.previewOrder.receipt}
                        />
                    </div>
                </div>
            )}
        </div>
    </MobileMaxWidthWrapper>
}

Checkout.propTypes = {
    hostname: PropTypes.string.isRequired,
}
