import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logPurchase } from "../../../util/analytics";
import Loading from "../../../component/loading/Loading";
import { RELEASE_PREPARE_ORDER_LOCK } from "../CheckoutButton";
import { useMutation } from "@apollo/react-hooks";

/**
 * 결제 완료 Redirect 페이지
 */
export default function CheckoutComplete() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [releaseLock] = useMutation(RELEASE_PREPARE_ORDER_LOCK);

  useEffect(() => {
    const requestPayData = JSON.parse(
      sessionStorage.getItem("lastProductInfo"),
    );
    if (queryParameters.get("imp_success") === "true") {
      logPurchase(
        "webapp",
        requestPayData.product,
        queryParameters.get("imp_uid"),
        requestPayData.amount,
        requestPayData.amount - requestPayData.product.price,
      );
      setTimeout(() => {
        navigate("/my/purchased");
      }, 1000);
    } else {
      if (requestPayData && requestPayData.product) {
        releaseLock({
          variables: {
            productId: Number(requestPayData.product.id),
          },
        });
      }
      alert(queryParameters.get("error_msg"));
      navigate("/");
    }
    //결제 완료 후 삭제
    sessionStorage.removeItem("lastProductInfo");
  }, []);

  return <Loading />;
}
