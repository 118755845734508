import React from 'react';
import PropTypes from 'prop-types';
import {PRODUCT_FRAGMENT} from "../productPreview/ProductPreview";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import SimilarProductList from "./SimilarProductList";

export const NUM_OF_SIMILAR_PRODUCTS = 6;
const GET_SIMILAR_PRODUCTS = gql`
  query getSimilarProducts($productIds: [Int!]!, $limit: Int) {
    getSimilarProducts(productIds: $productIds, limit: $limit) {
        related_products {
            ...ProductFragment   
        }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

/**
 * 비슷한 상품 API 호출 및 렌더링을 담당하는 컴포넌트
 * @param productId
 * @returns {Element}
 * @constructor
 */
export default function SimilarProductQueryWrapper({productId}) {
    const {data, error} = useQuery(GET_SIMILAR_PRODUCTS, {
        variables: {
            limit: NUM_OF_SIMILAR_PRODUCTS,
            productIds : [Number(productId)],
        },
        ssr: true,
    });

    if (error) {
        return <></>
    }

    return data && data.getSimilarProducts.length > 0 && data.getSimilarProducts[0].related_products.length > 0 ? (
        <div className={"SimilarProductList-container"}>
            <span className={"SimilarProductList-title"}>비슷한 상품</span>
            <SimilarProductList products={data.getSimilarProducts[0].related_products} limit={NUM_OF_SIMILAR_PRODUCTS} />
        </div>

    ) : <></>
}

SimilarProductQueryWrapper.propTypes = {
    productId: PropTypes.string.isRequired
}
